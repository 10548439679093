import React from 'react';
import {Accordion} from '../../../components/Accordion';
import GreyButton from '../../../components/GreyButton';
import {PrimaryButton} from '../../../components';

function EditableBusinessID({isSaving}) {
  return (
    <div className="mt-4" style={{opacity: isSaving ? 0.6 : 1}}>
      <div className="flex flex-col">
        <Accordion
          className="hover:bg-white-900"
          customTitle={
            <div className="flex items-center gap-3">
              <p className="font-medium text-grey">Business ID</p>

              <div className="ml-auto flex items-center gap-2">
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                  Certificate of incorporation
                </div>
              </div>
            </div>
          }
        >
          <div className="flex justify-end items-center mb-5 pt-1">
            <GreyButton
              className="mr-4"
              fontSize="text-xs"
              xPadding="px-2"
              yPadding="pt-[6px] pb-[5px]"
              buttonText="Cancel edit"
            />
            <PrimaryButton
              buttonText="Edit Info"
              fontSize="text-xs"
              xPadding="px-2"
              yPadding="pt-[10px] pb-[8px]"
            />
          </div>

          <div className="flex justify-between items-center bg-[#22222206] whitespace-nowrap px-4 py-4 border-grey-100 last:border-y border-b">
            <span className="text-xs font-medium uppercase text-grey">
              first name
            </span>
            <span>John Doe</span>
          </div>

          {/* RC Number */}
          <div className="flex justify-between items-center bg-[#22222206] whitespace-nowrap px-4 py-4 border-grey-100 last:border-y border-b">
            <span className="text-xs font-medium uppercase text-grey">
              RC Number
            </span>
            <span>123456789</span>
          </div>

          {/* Country */}
          <div className="flex justify-between items-center bg-[#22222206] whitespace-nowrap px-4 py-4 border-grey-100 last:border-y border-b">
            <span className="text-xs font-medium uppercase text-grey">
              Country
            </span>
            <span>Nigeria</span>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default EditableBusinessID;
