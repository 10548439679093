import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function SouthAfricaIdServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const [southAfricaId, setSouthAfricaId] = useState('');
  const [southAfricaIdWithPhoto, setSouthAfricaIdWithPhoto] = useState('');

  const southAfricaServicesQuery = useQuery(
    ['south_africa-kyc-services-query', id],
    () => getCompanyServices(id),
  );
  const url = southAfricaServicesQuery?.data?.pricing;

  useEffect(() => {
    const getSouthAfricaServicesQuery = () => {
      setSouthAfricaId((url?.za_kyc_id / 100).toFixed(2));
      setSouthAfricaIdWithPhoto((url?.za_kyc_id_with_photo / 100).toFixed(2));
    };

    getSouthAfricaServicesQuery();
  }, [url?.za_kyc_id, url?.za_kyc_id_with_photo]);

  return (
    <>
      {southAfricaServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">
                South Africa Kyc services
              </h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <label
                  htmlFor="angola_kyc_nin"
                  className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                >
                  South Africa ID
                  <input
                    id="south_africa_id"
                    type="number"
                    className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                    defaultValue={southAfricaId}
                    onChange={e =>
                      onUpdate({
                        za_kyc_id: e.target.value * 100,
                      })
                    }
                  />
                </label>
              </div>
              <div className="space-y-4">
                <label
                  htmlFor="angola_kyc_nin"
                  className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                >
                  South Africa ID With Photo
                  <input
                    id="south_africa_id_with_photo"
                    type="number"
                    className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                    defaultValue={southAfricaIdWithPhoto}
                    onChange={e =>
                      onUpdate({
                        za_kyc_id_with_photo: e.target.value * 100,
                      })
                    }
                  />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
