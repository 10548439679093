import {update} from '@intercom/messenger-js-sdk';

export function updateIntercomTags({
  moduleTags = [''],
  apiTags = [''],
  company_id = '',
  name = '',
}) {
  update({
    company: {
      name,
      id: company_id,
      activeModuleTags: [...moduleTags],
      apiTags: [...apiTags],
    },
  });
}
export function getEnabledServices(obj = {}) {
  return Object.keys(obj)
    .filter(key => obj[key] > 0 || obj[key] === true)
    .map(key =>
      key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
    );
}
