import React, {useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {connect, useDispatch} from 'react-redux';
import {back, verifiedBadge} from '../../assets/images/images';
import PrimaryButton from '../../components/PrimaryButton';
import Toggle from '../../components/Toggle';
import {
  getCompanyInfo,
  updateNegativeBilling,
  topUpCompanyWallet,
  getCompanySettings,
} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import GoToTop from '../../components/GoToTop';
import SingleDropdown from '../../components/SingleDropdown';
import {mutateCompany} from '../../helpers/queryFns/company';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';
import {COMPANY_TABS} from '../../helpers/constants';
import {
  getEnabledServices,
  updateIntercomTags,
} from '../../helpers/updateIntercomCompanyTags';

function CompanyTabs({queryKey = '', id, href, text, getInfo}) {
  const queryClient = useQueryClient();

  return (
    <NavLink
      end
      onMouseEnter={() => {
        if (queryKey) {
          queryClient.prefetchQuery([queryKey, id], () => getInfo(id));
        }
      }}
      to={`/company/${id}/${href}`}
      className={({isActive}) =>
        `text-grey60 font-medium text-tiny px-3 py-4${
          isActive ? ' activeCompanyTab' : ''
        }`
      }
    >
      {text}
    </NavLink>
  );
}

const dropdownData = [{env: 'Sandbox'}, {env: 'Production'}];

function CompanyLayout({children, company: {company_info}, auth: {user}}) {
  const [selected, setSelected] = useState(dropdownData[0]);
  const {id} = useParams();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(COMPANY_TABS);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === 'Compliance') {
      setTabs([
        ...COMPANY_TABS.filter(
          tab =>
            tab.text !== 'Settings' &&
            tab.text !== 'Services' &&
            tab.text !== 'Apps' &&
            tab.text !== 'API logs' &&
            tab.text !== 'Billings' &&
            tab.text !== 'Widgets' &&
            tab.text !== 'Users' &&
            tab.text !== 'Audit Trail' &&
            tab.text !== 'Teams',
        ),
      ]);
    }
  }, [user.role]);

  const queryClient = useQueryClient();
  const companyInfoQuery = useQuery(['companyInfo-query', id], () =>
    getCompanyInfo(id, dispatch),
  );

  useEffect(() => {
    const {pricing, modularisation, name} =
      (!companyInfoQuery?.isLoading && companyInfoQuery?.data?.company) || {};

    const activeModuleTags = getEnabledServices(modularisation);
    const activePriceTags = getEnabledServices(pricing);

    updateIntercomTags({
      company_id: id,
      name,
      moduleTags: activeModuleTags,
      apiTags: activePriceTags,
    });
  }, [
    companyInfoQuery?.data?.company,
    companyInfoQuery?.data?.company.pricing,
    companyInfoQuery?.isLoading,
    id,
  ]);
  useQuery(['companySettings-query'], () => getCompanySettings(dispatch));

  const {mutate: negativeBilling} = useMutation(
    negBill => updateNegativeBilling(id, negBill),
    {
      onSuccess: res => {
        toast.success(
          res.data.message || 'Service has been updated successfully',
        );
        queryClient.invalidateQueries('companyInfo-query');
      },
    },
  );

  const {mutate: topupWallet, isLoading: toppingUpWallet} = useMutation(
    topup => topUpCompanyWallet(id, topup),
    {
      onSettled: () => {
        setAmount('');
      },
      onSuccess: res => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('companyInfo-query');
        setOpen(false);
      },
    },
  );

  const {mutate: verifyCompany, isLoading: verifyingCompany} = useMutation(
    mutateCompany,
    {
      onSuccess: () => {
        toast.success('Yikes!! Company status updated successfully!');
        queryClient.invalidateQueries('companyInfo-query');
      },
    },
  );

  // validate topup field
  const [topUpError, setIsTopUpError] = useState(true);

  function handleSetAmount(e) {
    const {value} = e.target;
    setAmount(value);
    const isEmpty = value !== '';
    setIsTopUpError(isEmpty ? '' : 'Please enter a valid amount');
  }

  const interests = companyInfoQuery.isLoading
    ? '...'
    : companyInfoQuery.data?.company?.interests || {};

  const keys = Object.keys(interests);

  const booleanKeys = [];

  let isEmpty = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const item = interests[key];
    if (typeof item === 'boolean' && item === true) {
      booleanKeys.push(key);
    }
  }

  if (booleanKeys.length < 1) {
    isEmpty = true;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="flex items-center !font-inter gap-2 font-medium text-tiny text-brandBlue"
      >
        <img src={back} alt="" />
        Back to Company
      </button>

      {companyInfoQuery?.isLoading ? (
        <Loader height="15" />
      ) : (
        <>
          <div className="flex flex-wrap gap-6 mt-5 sm:flex-nowrap w-fit">
            <div className="w-[78px] max-w-[78px] sm:max-w-[140px] sm:w-full flex items-center justify-center max-h-[78px] h-[78px] sm:max-h-[140px] sm:h-[140px] bg-grey80 text-[40px] sm:text-[72px] text-white rounded-full leading-none">
              {companyInfoQuery?.isLoading
                ? '-'
                : companyInfoQuery?.data?.company?.name
                    ?.substr(0, 1)
                    ?.toUpperCase()}
            </div>
            <div className="flex flex-wrap gap-4">
              <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit">
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  Company name
                </h3>

                {companyInfoQuery.isLoading ? (
                  <p className="text-xs">-</p>
                ) : (
                  <div className="flex items-center gap-1">
                    <h4 className="text-lg font-bold text-grey80">
                      {companyInfoQuery?.data?.company?.name}
                    </h4>
                    {companyInfoQuery?.data?.company?.verified ? (
                      <img src={verifiedBadge} alt="" />
                    ) : null}
                  </div>
                )}
              </div>
              <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit">
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  Account number
                </h3>
                <h4 className="text-lg font-bold text-grey80">
                  {companyInfoQuery?.data?.company?.account_number}
                </h4>
              </div>
              <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit">
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  Bank name
                </h3>
                <h4 className="text-lg font-bold text-grey80">
                  {companyInfoQuery?.data?.company?.account_bank ?? '-'}
                </h4>
              </div>
              <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit">
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  Wallet balance
                </h3>
                <h4 className="text-lg font-bold text-grey80">
                  {formatNumberToCurrency(
                    companyInfoQuery?.data?.company?.wallet_balance / 100 ?? 0,
                    company_info?.company?.currency,
                  )}
                </h4>
              </div>
              <div className="flex flex-col px-4 py-2 ml-0 space-y-2 rounded-lg bg-lighterBlue w-fit">
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  Interest
                </h3>
                <div className="flex flex-wrap gap-2 sm:flex-nowrap">
                  {!!isEmpty && (
                    <h4 className="px-3 py-1 rounded-[5px] bg-lightBlue text-grey80 font-medium text-sm w-fit">
                      No interests
                    </h4>
                  )}
                  {booleanKeys.map(key => (
                    <h4
                      key={key}
                      className="px-3 py-1 rounded-[5px] bg-lightBlue text-grey80 font-medium text-sm w-fit"
                    >
                      {key}
                    </h4>
                  ))}
                </div>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap gap-4 px-4 py-[14px] bg-lighterBlue rounded-lg w-fit">
                <PrimaryButton
                  buttonText="Top up account"
                  className="px-4 font-medium"
                  onClick={() => setOpen(!open)}
                  disabled={
                    (user?.role?.includes('Compliance') ||
                      user?.role?.includes('Retention')) &&
                    !user?.role?.includes('Admin')
                  }
                />
                <Modal show={open} onClose={setOpen}>
                  <form
                    onSubmit={e => e.preventDefault()}
                    className="max-w-sm mx-auto sm:my-8"
                  >
                    <h2 className="mb-10 text-base font-medium text-grey80">
                      Topup wallet
                    </h2>
                    <label
                      htmlFor="topup_amount"
                      className="flex flex-col items-start max-w-full mb-6 text-sm label text-grey60"
                    >
                      Enter amount
                      <input
                        id="topup_amount"
                        value={amount}
                        onChange={handleSetAmount}
                        type="number"
                        className="max-w-full mt-1 input"
                      />
                    </label>
                    <p className="absolute text-sm text-danger top-24 right-12">
                      {topUpError}
                    </p>

                    <label className="flex items-start mb-1 text-sm text-left label text-grey60">
                      Environment
                    </label>
                    <SingleDropdown
                      dropdownData={dropdownData}
                      selected={selected}
                      setSelected={setSelected}
                      spanText={selected.env}
                      width="w-full"
                    />

                    <PrimaryButton
                      className="inline-flex justify-center w-full mt-6 text-white rounded text-tiny bg-brandBlue"
                      buttonText="Continue with payment"
                      disabled={topUpError || toppingUpWallet}
                      loadingState={toppingUpWallet}
                      onClick={() =>
                        topupWallet({
                          amount: amount * 100,
                          env: selected.env,
                        })
                      }
                    />
                    <button
                      type="button"
                      className="inline-flex justify-center w-full mt-6 text-navGrey"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </form>
                </Modal>
                <div
                  className={`flex items-center gap-6 px-4 py-3 font-medium rounded bg-lightBlue text-brandBlue text-tiny ${
                    (user?.role?.includes('Compliance') ||
                      user?.role?.includes('Retention')) &&
                    !user?.role?.includes('Admin')
                      ? 'hidden'
                      : ''
                  }`}
                >
                  Negative balance
                  <Toggle
                    enabled={companyInfoQuery.data?.company?.negative_billing}
                    setEnabled={() =>
                      negativeBilling({
                        negative_billing:
                          !companyInfoQuery?.data?.company?.negative_billing,
                      })
                    }
                  />
                </div>
              </div>
              <PrimaryButton
                className="self-center px-4 font-medium h-fit"
                buttonText={
                  companyInfoQuery?.data?.company?.verified
                    ? 'Unverify company'
                    : 'Verify company'
                }
                disabled={Boolean(verifyingCompany)}
                loadingState={verifyingCompany}
                onClick={() =>
                  verifyCompany({
                    company_id: id,
                    type: companyInfoQuery?.data?.company?.verified
                      ? 'deactivate'
                      : 'activate',
                  })
                }
              />
            </div>
          </div>

          {/* company nav */}
          <div className="mt-8 border-b border-borderGrey">
            <div className="flex items-center space-x-4 overflow-x-auto company-nav whitespace-nowrap">
              {tabs.map((tab, index) => (
                <CompanyTabs id={id} key={index} {...tab} />
              ))}
            </div>
          </div>
        </>
      )}
      {children}
      <GoToTop />
    </>
  );
}

export default connect(state => state, null)(CompanyLayout);
