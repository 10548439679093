import React, {useCallback, useEffect, useState} from 'react';
import Select from 'react-select';
import moment from 'moment/moment';

import {
  isObjectEmpty,
  isObjectValueEmpty,
} from '../../../helpers/isObjectEmpty';
import GreyButton from '../../../components/GreyButton';
import {
  customStyles,
  DropdownIndicator,
  PrimaryButton,
} from '../../../components';
import {VerificationGender, VerificationTypes} from './verificationTypes';
import {Accordion} from '../../../components/Accordion';

export function EditableGovData({
  isSaving,
  govData,
  vStatus,
  isEditing,
  setIsEditing,
  updateVerificationDocs,
  slug,
  title,
  others,
  canReview,
  vType,
  hasId,
}) {
  const [editableData, setEditableData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [documentType, setDocumentType] = useState({});
  const [gender, setGender] = useState({});

  const onContentChange = useCallback(
    (key, value, data) => {
      const {extras, ...rest} = editableData;
      setIsValid(isObjectValueEmpty(rest));
      setEditableData({...data, ...editableData, [key]: value});
    },
    [editableData],
  );

  const getDropdownData = (key, value) =>
    ({
      document_type: {
        defaultValue:
          VerificationTypes.find(v => v.value === value) ||
          documentType ||
          VerificationTypes[0],
        options: VerificationTypes,
        setFunction: setDocumentType,
      },
      gender: {
        defaultValue:
          VerificationGender.find(v => v.value === value) ||
          gender ||
          VerificationGender[0],
        options: VerificationGender,
        setFunction: setGender,
      },
    }[key]);

  const handEditDocument = async (index, item) => {
    setIsEditing(prev => ({
      [index]: !prev[index],
    }));

    if (
      isEditing[index] &&
      !isObjectEmpty(editableData) &&
      JSON.stringify(Object.values(item)) !==
        JSON.stringify(Object.values(editableData))
    ) {
      const {id, datetime, country, type, extras, ...rest} = editableData;
      if (id) {
        await updateVerificationDocs({
          refId: slug,
          docId: id,
          data: {...rest},
        });
      }
    }
  };
  useEffect(() => {
    const {extras, ...rest} = editableData;
    setIsValid(isObjectValueEmpty(rest));
  }, [editableData, isValid]);

  const checkId = ['license_number', 'bvn', 'nin'];
  const dontDisplay = [
    'id',
    'gov_data_image',
    'image',
    'image_url',
    'customer_id',
    'updated_date',
    'created_date',
    'createdAt',
    'updatedAt',
    'photo',
  ];

  return (
    <div className="mt-10" style={{opacity: isSaving ? 0.6 : 1}}>
      <div className="flex flex-col mt-4">
        {govData?.map((item, index) => (
          <Accordion
            key={index}
            className="hover:bg-white-900"
            isOpen={index === 1}
            customTitle={
              <div className="flex items-center gap-3">
                <p className="font-medium text-grey">{title}</p>
                {others || hasId ? (
                  <div className="ml-auto flex items-center gap-2">
                    <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                      {item?.type}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`uppercase ${
                        canReview ? 'ml-0' : 'ml-auto'
                      } pt-[5px] pb-[3px]  px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]`}
                    >
                      {vType}
                    </div>
                  </>
                )}
              </div>
            }
          >
            <div className="flex  justify-start items-center mb-5 pt-1">
              {!others && item?.id && canReview && (
                <div className="ml-auto">
                  {vStatus === 'pending' && (
                    <div className="flex">
                      {isEditing[index] && (
                        <GreyButton
                          className="mr-4"
                          fontSize="text-xs"
                          xPadding="px-2"
                          yPadding="pt-[6px] pb-[5px]"
                          buttonText="Cancel edit"
                          onClick={() => {
                            setIsEditing(prev => ({
                              [index]: !prev[index],
                            }));
                          }}
                        />
                      )}
                      <PrimaryButton
                        disabled={isEditing[index] && (isSaving || !isValid)}
                        buttonText={
                          isEditing[index]
                            ? 'Save document'
                            : isEditing[index] && isSaving
                            ? 'Updating...'
                            : 'Edit document'
                        }
                        fontSize="text-xs"
                        xPadding="px-2"
                        yPadding="pt-[10px] pb-[8px]"
                        onClick={() => handEditDocument(index, item)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {others ||
              (hasId && (
                <div className="flex flex-col mb-5 justify-center h-fit w-full">
                  <div className="relative flex justify-center">
                    <img
                      src={
                        `${`data:image/png;base64,${
                          item?.image || item?.photo
                        }`}` ||
                        item?.image ||
                        item?.photo
                      }
                      alt=""
                      className="mt-6 max-h-[192px]"
                    />
                  </div>
                </div>
              ))}
            {Object.entries(item)?.map(
              ([key, value]) =>
                ![
                  ...dontDisplay,
                  ...checkId.filter(
                    k => k.toLowerCase() !== item.type?.toLowerCase(),
                  ),
                ].includes(key) && (
                  <div
                    key={key}
                    className="flex justify-between items-center bg-[#22222206] whitespace-nowrap px-4 py-4 border-grey-100 last:border-y border-b"
                  >
                    <span className="text-xs font-medium uppercase text-grey">
                      {key.replaceAll('_', ' ')}
                    </span>

                    {!key.replaceAll('_', ' ').includes('date') ? (
                      <>
                        {['document_type', 'gender'].includes(key) ? (
                          <>
                            {isEditing[index] ? (
                              <Select
                                defaultValue={
                                  getDropdownData(key, value).defaultValue
                                }
                                onChange={data => {
                                  // setDocumentType(data);
                                  getDropdownData(key, value).setFunction(data);
                                  onContentChange(key, data?.value, item);
                                }}
                                options={getDropdownData(key, value).options}
                                multiValueLabel
                                placeholder="Select roles"
                                className="basic-multi-select text-left bg-white min-w-[200px]"
                                classNamePrefix="react-select"
                                components={{DropdownIndicator}}
                                styles={customStyles}
                                menuPlacement="auto"
                              />
                            ) : (
                              <span>
                                {getDropdownData(key, value).defaultValue.label}
                              </span>
                            )}
                          </>
                        ) : (
                          <div
                            className={`text-sm text-body capitalize ${
                              isEditing[index] && !others
                                ? 'outline outline-1 outline-[#DEDEE0] bg-white  px-2 rounded'
                                : ''
                            } `}
                            contentEditable={
                              isEditing[index] && !others ? 'true' : 'false'
                            }
                            onInput={e =>
                              onContentChange(key, e.target.textContent, item)
                            }
                            suppressContentEditableWarning
                          >
                            {value}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {isEditing[index] && key !== 'datetime' ? (
                          <input
                            type={
                              value.includes(', ') ? 'datetime-local' : 'date'
                            }
                            name={key}
                            className={`justify-start ${
                              isEditing[index] && !others
                                ? 'outline outline-1 outline-[#DEDEE0] bg-white  px-2 rounded'
                                : 'bg-[#22222206]'
                            }`}
                            value={`${
                              value.includes(', ')
                                ? moment(editableData[key]).format(
                                    'YYYY-MM-DD hh:mm:ss',
                                  ) ||
                                  moment(value).format('YYYY-MM-DD hh:mm:ss')
                                : editableData[key] || value
                            }`}
                            placeholder={`${value}`}
                            onChange={e => {
                              onContentChange(key, e.target.value, item);
                            }}
                            // min={new Date().toISOString().split('T')[0]}
                            disabled={!isEditing[index] && !others}
                          />
                        ) : (
                          <span>{value}</span>
                        )}
                      </>
                    )}
                  </div>
                ),
            )}
            <hr />
          </Accordion>
        ))}
      </div>
    </div>
  );
}
