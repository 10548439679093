import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';

export default function GreyButton({
  buttonText,
  xPadding = 'px-4',
  yPadding = 'pt-[18px] pb-[14px]',
  className = '',
  fontSize = 'text-sm',
  onClick,
  loading,
  color,
  src,
  alt,
  width,
  height,
  imgClass = 'hidden md:block',
  background,
  ...props
}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} ${
        loading ? 'flex items-center justify-center relative' : ''
      } bg-white-900 text-grey ${fontSize} font-medium max-h-12 hover:bg-opacity-90 duration-200 transition rounded outline outline-1 outline-black-300 ${xPadding} ${yPadding}`}
      style={{
        ...(color && {color}),
        ...(background && {background}),
        ...(props?.style && props.style),
      }}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <PuffLoader css={override} size={35} color={color || '#ffffff'} />
      ) : null}
      <span>{buttonText}</span>
      {src && (
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          className={imgClass}
        />
      )}
    </button>
  );
}
