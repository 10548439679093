import React, {useState, useEffect} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {dropdownBlue} from '../../assets/images/images';
import Toggle from '../Toggle';
import {
  getCompanyInfo,
  getCompanyServices,
  updateKycServices,
} from '../../helpers/queryFns/fetchCompanyDetails';

export default function KycServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const queryClient = useQueryClient();

  const [kycBvnBasic, setKycBvnBasic] = useState('');
  const [kycBvnFull, setKycBvnFull] = useState('');
  const [kycBvnAdvance, setKycBvnAdvance] = useState('');
  const [kycMobileBasic, setKycMobileBasic] = useState('');
  const [kycMobileAdvance, setKycMobileAdvance] = useState('');
  const [kycBvnVerify, setKycBvnVerify] = useState('');
  const [kycNinVerify, setKycNinVerify] = useState('');
  const [kycNin, setKycNin] = useState('');
  const [KycNinSlip, setKycNinSlip] = useState('');
  const [kycPhotoVerify, setKycPhotoVerify] = useState('');
  const [kycNuban, setKycNuban] = useState('');
  const [kycDriversLicense, setKycDriversLicense] = useState('');
  const [kycCac, setKycCac] = useState('');
  const [kycAdvanceCac, setKycAdvanceCac] = useState('');
  const [kycUserScreening, setKycUserScreening] = useState('');
  const [aml, setAml] = useState('');
  const [passport, setPassport] = useState('');
  const [creditCheckId, setCreditCheckId] = useState('');

  const kycServicesQuery = useQuery(['kyc-services-query', id], () =>
    getCompanyServices(id),
  );

  const url = kycServicesQuery?.data?.pricing;

  useEffect(() => {
    const getKycServicesQuery = () => {
      setKycBvnBasic((url?.kyc_bvn_basic / 100).toFixed(2));
      setKycBvnFull((url?.kyc_bvn_full / 100).toFixed(2));
      setKycBvnAdvance((url?.kyc_bvn_advance / 100).toFixed(2));
      setKycMobileBasic((url?.kyc_mobile_basic / 100).toFixed(2));
      setKycMobileAdvance((url?.kyc_mobile / 100).toFixed(2));
      setKycBvnVerify((url?.kyc_bvn_verify / 100).toFixed(2));
      setKycNinVerify((url?.kyc_nin_verify / 100).toFixed(2));
      setKycNin((url?.kyc_nin / 100).toFixed(2));
      setKycNinSlip((url?.kyc_nin / 100).toFixed(2));
      setKycPhotoVerify((url?.kyc_photo_verify / 100).toFixed(2));
      setKycNuban((url?.kyc_nuban / 100).toFixed(2));
      setKycDriversLicense((url?.kyc_drivers_license / 100).toFixed(2));
      setKycCac((url?.kyc_cac / 100).toFixed(2));
      setKycAdvanceCac((url?.kyc_cac_advance / 100).toFixed(2));
      setKycUserScreening((url?.aml_user_screening / 100).toFixed(2));
      setAml((url?.aml / 100).toFixed(2));
      setPassport((url?.passport / 100).toFixed(2));
      setCreditCheckId((url?.credit_check / 100).toFixed(2));
    };

    getKycServicesQuery();
  }, [
    url?.aml,
    url?.kyc_bvn_basic,
    url?.kyc_bvn_full,
    url?.kyc_bvn_advance,
    url?.aml_user_screening,
    url?.kyc_bvn_verify,
    url?.kyc_cac,
    url?.kyc_cac_advance,
    url?.kyc_drivers_license,
    url?.kyc_mobile,
    url?.kyc_mobile_basic,
    url?.kyc_nin,
    url?.kyc_nin_slip,
    url?.kyc_nin_verify,
    url?.kyc_nuban,
    url?.kyc_photo_verify,
    url?.passport,
    url?.credit_check,
  ]);

  const companyInfoQuery = useQuery(['companyInfo-query', id], () =>
    getCompanyInfo(id),
  );

  const {mutate} = useMutation(
    kyc_services => updateKycServices(id, kyc_services),
    {
      onSuccess: res => {
        toast.success(
          res.data.message || 'Service has been updated successfully',
        );
        queryClient.invalidateQueries('companyInfo-query');
      },
    },
  );

  return (
    <>
      {kycServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Kyc services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="lookup_bvn_basic"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup BVN (Basic)
                    <input
                      id="lookup_bvn_basic"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycBvnBasic}
                      onChange={e =>
                        onUpdate({kyc_bvn_basic: e.target.value * 100})
                      }
                    />
                  </label>
                  <Toggle
                    enabled={companyInfoQuery.data?.company.bvn_basic_service}
                    setEnabled={() =>
                      mutate({
                        bvn_basic_service:
                          !companyInfoQuery.data?.company.bvn_basic_service,
                      })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="lookup_bvn_advance"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup BVN (Advance)
                    <input
                      id="lookup_bvn_advance"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycBvnAdvance}
                      onChange={e =>
                        onUpdate({kyc_bvn_advance: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="age_id_verification"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Age/Identity verification
                    <input
                      id="age_id_verification"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_phone_basic"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup phone no. (Basic)
                    <input
                      id="lookup_phone_basic"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycMobileBasic}
                      onChange={e =>
                        onUpdate({kyc_mobile_basic: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="bvn_verification"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    BVN verification
                    <input
                      id="bvn_verification"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycBvnVerify}
                      onChange={e =>
                        onUpdate({kyc_bvn_verify: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_vin"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup VIN
                    <input
                      id="lookup_vin"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_nin_verify"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup NIN verify
                    <input
                      id="lookup_nin_verify"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycNinVerify}
                      onChange={e =>
                        onUpdate({kyc_nin_verify: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_nin"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup NIN
                    <input
                      id="lookup_nin"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycNin}
                      onChange={e => onUpdate({kyc_nin: e.target.value * 100})}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="passport_api"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Passport API
                    <input
                      id="aml"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={passport}
                      onChange={e => onUpdate({passport: e.target.value * 100})}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="aml"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    AML
                    <input
                      id="aml"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={aml}
                      onChange={e => onUpdate({aml: e.target.value * 100})}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="credit_check"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Credit Check
                    <input
                      id="credit_check"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={creditCheckId}
                      onChange={e =>
                        onUpdate({
                          credit_check: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="lookup_bvn_full"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup BVN (Full)
                    <input
                      id="lookup_bvn_full"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycBvnFull}
                      onChange={e =>
                        onUpdate({kyc_bvn_full: e.target.value * 100})
                      }
                    />
                  </label>
                  <Toggle
                    enabled={companyInfoQuery.data?.company.bvn_full_service}
                    setEnabled={() =>
                      mutate({
                        bvn_full_service:
                          !companyInfoQuery.data?.company.bvn_full_service,
                      })
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor="lookup_tin"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup TIN
                    <input
                      id="lookup_tin"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_phone_advance"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup phone no. (Advance)
                    <input
                      id="lookup_phone_advance"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycMobileAdvance}
                      onChange={e =>
                        onUpdate({kyc_mobile: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="photo_verification"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Photo verification
                    <input
                      id="photo_verification"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycPhotoVerify}
                      onChange={e =>
                        onUpdate({kyc_photo_verify: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_nuban"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup NUBAN
                    <input
                      id="lookup_nuban"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycNuban}
                      onChange={e =>
                        onUpdate({kyc_nuban: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_drivers_license"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup driver’s license
                    <input
                      id="lookup_drivers_license"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycDriversLicense}
                      onChange={e =>
                        onUpdate({kyc_drivers_license: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_cac"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup CAC
                    <input
                      id="lookup_cac"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycCac}
                      onChange={e => onUpdate({kyc_cac: e.target.value * 100})}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="advanced_cac_lookup"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Advanced CAC Lookup
                    <input
                      id="advanced_cac_lookup"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycAdvanceCac}
                      onChange={e =>
                        onUpdate({kyc_cac_advance: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="business_verification"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Business verification
                    <input
                      id="business_verification"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      disabled
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="lookup_nin_slip"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Lookup NIN Slip
                    <input
                      id="lookup_nin_slip"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={KycNinSlip}
                      onChange={e =>
                        onUpdate({kyc_nin_slip: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="aml_user_screening"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    User Screening
                    <input
                      id="aml_user_screening"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={kycUserScreening}
                      onChange={e =>
                        onUpdate({aml_user_screening: e.target.value * 100})
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
