import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  // biodataIc,
  // addressIc,
  // ipDeviceCheckIc,
  // amlCheckIc,
  chevronRightSm,
  placeholder,
} from '../../../assets/images/images';
import {DashboardLayout, Loader, PrimaryButton} from '../../../components';
// import PAGES_URL from '../../pageRoutes';
import ReviewUserModal from './ReviewUserModal';
import ActivityLog from './ActivityLog';
// import ReusableLink from './ReusableLink';
import SessionsModal from './SessionsModal';
import GreyButton from '../../../components/GreyButton';
import ExpandImageToggle from './ExpandImageToggle';
import {getStatusStyles} from '../../../helpers/getStatusStyles';

// const LINKS = [
//   {
//     path: PAGES_URL.VERIFICATIONSBIODATA,
//     src: biodataIc,
//     text: 'Bio data',
//   },
// {
//   path: PAGES_URL.VERIFICATIONSIPDEVICECHECK,
//   src: ipDeviceCheckIc,
//   text: 'IP/Device Check',
// },
// {
//   path: PAGES_URL.VERIFICATIONSAMLCHECK,
//   src: amlCheckIc,
//   text: 'AML Check',
// },
// {
//   path: PAGES_URL.VERIFICATIONSADDRESS,
//   src: addressIc,
//   text: 'Address',
// },
// ];

function Layout({
  verificationId,
  children,
  loading,
  isLoading,
  setCanReview,
  canReview,
  resizeImage,
}) {
  const [openReview, setOpenReview] = useState(false);
  const [openSessions, setOpenSessions] = useState(false);
  const [isReviewable, setIsReviewable] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const {slug} = useParams();

  const {
    verifications: {session_summary},
    auth: {user},
  } = useSelector(state => state);

  const VERIFICATION = session_summary?.data?.Verification;
  const vStatus = VERIFICATION?.status?.toLowerCase();
  const vType = VERIFICATION?.verification_type?.toLowerCase();
  const reviewer = VERIFICATION?.reviewer;
  const reviewerActivity = session_summary?.data?.reviewer_activity || {};
  const livenessConfig = session_summary?.data?.liveness_config || {};
  const REVIEWER_ACTIVITY = session_summary?.data?.reviewer_activity;
  const rStatus = REVIEWER_ACTIVITY?.reviewer_status?.toLowerCase();
  const fraudDecisionActivity =
    session_summary?.data?.fraud_decision_activity || {};

  useEffect(() => {
    if (!loading) {
      setIsLoadingImage(true);
      setCanReview(reviewer && reviewer === user?.email);
      setIsReviewable(vStatus === 'pending');
      setIsLoadingImage(false);
    }
  }, [loading, reviewer, user?.email, vStatus, setCanReview]);

  return (
    <DashboardLayout
      topPadding="pt-0"
      noMarginTop
      // className="pl-12"
      bg="bg-white-900"
    >
      <ReviewUserModal open={openReview} setOpen={setOpenReview} />
      <SessionsModal open={openSessions} setOpen={setOpenSessions} />
      <div className="flex items-start min-h-full flex-col md:flex-row gap-2 mt-4 basis-full sm:mt-0 w-full">
        {/* <div className="basis-[17.14%] flex flex-col gap-6 pt-[35px] sticky top-12"> */}
        {/*  <h2 className="text-base font-medium text-grey sm:text-lg whitespace-nowrap"> */}
        {/*    Verifications Details */}
        {/*  </h2> */}
        {/*  <div className="flex flex-col gap-2 p-2"> */}
        {/*    {LINKS.map(({path, src, text}) => ( */}
        {/*      <ReusableLink */}
        {/*        key={path} */}
        {/*        path={path.replace(':slug', verificationId || slug)} */}
        {/*        src={src} */}
        {/*        text={text} */}
        {/*      /> */}
        {/*    ))} */}
        {/*  </div> */}
        {/* </div> */}

        <div className="md:ml-[20px] basis-[45.67%]d p-8 pt-[36px] bg-white w-full md:w-[50%]">
          {children}
        </div>

        <div className="basis-[36.04%]w px-6 py-10 sticky top-0 w-full md:w-[50%]">
          <div className="flex items-center justify-between transition-all">
            <div className="flex gap-5 items-center">
              <div
                className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs  font-medium ${
                  getStatusStyles(vStatus).styles
                } rounded-full w-fit items-start gap-[2px]`}
              >
                {getStatusStyles(vStatus).text}
              </div>

              <div className="flex p-2 py-1 items-center bg-brandBlue4 rounded">
                <p className="font-medium text-xs">{"Reviewer's Status"}</p>
                <div
                  className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs ml-3  font-medium ${
                    getStatusStyles(rStatus || 'pending').styles
                  } rounded-full w-fit items-start gap-[2px]`}
                >
                  {getStatusStyles(rStatus || 'pending').text}
                </div>
              </div>
            </div>

            {isReviewable && (
              <PrimaryButton
                buttonText="Review verification"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                onClick={() => setOpenReview(!openReview)}
                disabled={!canReview}
              />
            )}
          </div>
          <div className="p-4 mt-4 bg-white rounded">
            <div className="mt-2">
              <p className="mb-4 text-xs font-medium uppercase text-grey">
                Reviewer&apos;s Activity
              </p>
              <div className="flex items-center gap-1">
                <div className="flex uppercase items-center justify-center w-6 h-6 pt-[2px] text-xs font-medium text-brandBlue rounded-full bg-brandBlue40">
                  {`${reviewer?.substring(0, 1) || '-'}`}
                </div>
                <p className=" capitalize font-medium text-grey">
                  {`${reviewer?.replace(/@.*$/, '') || '-'}`}
                </p>
              </div>

              {isLoading ? (
                <Loader height={5} />
              ) : (
                Object.entries(reviewerActivity)?.map(([key, value]) => (
                  <div
                    key={key}
                    className="flex mt-2 text-sm flex-wrap md:items-center w-full"
                  >
                    <p className="capitalize text-xs font-medium text-body w-[100px]">
                      {key.replaceAll('_', ' ')?.replaceAll('reviewer', '')}:
                    </p>
                    <div className=" flex-1 ">
                      <p className="text-body text-sm text-left">
                        {key === 'timestamp' && value !== '-'
                          ? moment(value).format('DD MMM YYYY hh:mm a')
                          : value || '-'}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1 p-4 mt-4 text-xs uppercase bg-white rounded text-grey60">
            <span className="text-grey font-medium">Reference ID</span>
            <span>{verificationId || slug}</span>
          </div>{' '}
          <>
            <div className="flex flex-col gap-1 p-4 mt-4 text-xs uppercase bg-white rounded ">
              <span className="font-medium">Selfie configuration</span>
              <div
                className={`flex uppercase text-white pt-[5px] pb-[3px] px-2 text-xs  font-medium ${
                  !livenessConfig?.glasses_check ? 'bg-accent-500' : 'bg-danger'
                } rounded-full w-fit items-start gap-[2px]`}
              >
                {`Eye glasses ${
                  !livenessConfig?.glasses_check ? '' : 'not'
                } allowed`}
              </div>
            </div>
            <div className="flex flex-col gap-2 p-4 mt-4 mb-8 text-xs  bg-white rounded ">
              <span className="font-medium uppercase">
                Brightness configuration
              </span>
              <div>
                <p className={'m-0 p-0'}>
                  {`${
                    livenessConfig?.brightness_threshold || 0
                  }% brightness threshold set.`}
                </p>
                <span>{"Ensure customer's selfie is very bright"}</span>
              </div>
            </div>
          </>
          {VERIFICATION?.id_url ? (
            <div className="flex flex-col gap-4 mt-4">
              <div className="flex items-center gap-2">
                <p className="font-medium text-grey mr-2">Government ID</p>
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                  {vType}
                </div>
              </div>
              <div>
                {isLoadingImage ? (
                  <div className="w-full h-[192px] bg-grey-200 animate-pulse" />
                ) : (
                  <div className="relative flex flex-col gap-4">
                    <div className="relative">
                      <p className="text-xs font-bold uppercase text-black">
                        Front
                      </p>
                      <img
                        src={
                          VERIFICATION?.id_url ||
                          VERIFICATION?.failed_id_capture ||
                          placeholder
                        }
                        alt=""
                        className="w-full object-cover max-h-[192px]"
                        id="id-url"
                      />
                      <ExpandImageToggle
                        resizeImage={resizeImage}
                        imgUrl={
                          VERIFICATION?.failed_id_capture ||
                          VERIFICATION?.id_url ||
                          placeholder
                        }
                        id="id-url"
                      />
                    </div>

                    {VERIFICATION?.back_url && (
                      <div className="relative">
                        <p className="text-xs font-bold uppercase text-black">
                          Back
                        </p>
                        <img
                          src={VERIFICATION?.back_url || placeholder}
                          alt=""
                          className="w-full object-cover max-h-[192px]"
                          id="back-url"
                        />
                        <ExpandImageToggle
                          resizeImage={resizeImage}
                          imgUrl={VERIFICATION?.back_url || placeholder}
                          id="back-url"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex items-center gap-2">
              <p className="font-medium text-grey mr-2">Business ID</p>
              <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                Certificate of incorporation
              </div>
            </div>
            <div className="relative">
              <img
                src={VERIFICATION?.back_url || placeholder}
                alt=""
                className="w-full object-cover max-h-[192px]"
                id="back-url"
              />
              <ExpandImageToggle
                resizeImage={resizeImage}
                imgUrl={VERIFICATION?.back_url || placeholder}
                id="back-url"
              />
            </div>
          </div>
          <div className="flex items-stretch gap-4 mt-4 w-full">
            <div className="p-4 bg-white rounded w-1/2">
              <p className="mt-2 text-xs font-medium uppercase text-grey">
                Total time spent
              </p>
              <p className="mt-2 text-xs text-grey60">
                The amount of time taken to process verification details{' '}
              </p>
              <p className="mt-2 text-xl font-medium text-grey">
                {VERIFICATION?.completion_time
                  ?.replace('m', ':')
                  ?.replace('s', '') || '00:00'}
              </p>
            </div>
            <div className="p-4 bg-white rounded w-1/2">
              <p className="mt-2 text-xs font-medium uppercase text-grey">
                SESSION
              </p>
              <p className="mt-2 text-xs text-grey60">Number of sessions</p>
              <p className="mt-2 text-xl font-medium text-grey">
                {session_summary?.data?.sessions?.length || 1}
              </p>
              <GreyButton
                className="flex items-start gap-2 mt-2"
                fontSize="text-xs"
                xPadding="px-2"
                yPadding="pt-[9px] pb-[7px]"
                buttonText="View all sessions"
                src={chevronRightSm}
                width={12}
                height={12}
                onClick={() => setOpenSessions(!openSessions)}
              />
            </div>
          </div>
          <div className="flex items-stretch gap-4 mt-4 w-full">
            <div className="p-4 bg-white rounded w-full">
              <p className="mt-2 text-xs font-medium uppercase text-grey">
                Date/time created
              </p>
              <p className="mt-2 font-medium text-grey">
                {loading
                  ? '-'
                  : moment(VERIFICATION?.date_created).format(
                      'DD MMM YYYY, hh:mm A',
                    )}
              </p>
            </div>
          </div>
          <div className="p-4 mt-4 bg-white rounded">
            <div className="mt-2">
              <p className="mb-4 text-xs font-medium uppercase text-grey">
                FRAUD DECISION LOG
              </p>

              <div className="flex mt-2 text-sm flex-wrap md:items-center w-full">
                <p className="text-xs font-medium text-body w-20">Status:</p>
                <div className="flex-1">
                  <p className="text-body text-sm text-left">
                    {fraudDecisionActivity?.status || '-'}
                  </p>
                </div>
              </div>
              <div className="flex mt-2 text-sm flex-wrap md:items-center w-full">
                <p className="text-xs font-medium text-body w-20">Comment:</p>
                <div className="flex-1">
                  <p className="text-body text-sm text-left">
                    {fraudDecisionActivity?.comment || '-'}
                  </p>
                </div>
              </div>
              <div className="flex mt-2 text-sm flex-wrap md:items-center w-full">
                <p className="text-xs font-medium text-body w-20">Timestamp:</p>
                <div className="flex-1">
                  <p className="text-body text-sm text-left">
                    {moment(fraudDecisionActivity?.timestamp).format(
                      'DD MMM YYYY hh:mm a',
                    ) || '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ActivityLog />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Layout;
