import React, {useEffect} from 'react';
import Intercom from '@intercom/messenger-js-sdk';

import {ReactQueryDevtools} from 'react-query/devtools';

import {connect} from 'react-redux';
import Routes from './routes';

function Main({auth: {user = {}}}) {
  const route = window.location.href;
  const intercomAppId =
    process.env.REACT_APP_APP_ENV_MODE === 'production'
      ? process.env.REACT_APP_INTERCOM_PROD_APP_ID
      : process.env.REACT_APP_INTERCOM_DEV_STAGING_APP_ID;

  Intercom({
    app_id: intercomAppId,
    user_id: user?.id,
    name: `${user?.first_name} ${user?.last_name}`,
    email: user?.email,
    created_at: user?.createdAt,
  });

  useEffect(() => {
    const isProdSuperAdmin =
      route.includes('super-admin-settings') &&
      process.env.REACT_APP_APP_ENV_MODE !== 'production';

    if (
      (isProdSuperAdmin && user?.role?.includes('Admin')) ||
      (isProdSuperAdmin && user?.email !== 'tobi@dojah.io')
    ) {
      window.location.href = '/not-allowed';
    }
  }, [route, user?.email, user?.role]);

  return (
    <>
      <Routes />
      <ReactQueryDevtools />
    </>
  );
}
export default connect(state => state, null)(Main);
